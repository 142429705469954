import React from 'react';
import Main from '../../containers/Layout';
import carDealershipPageContent from '../../../content/pages/marketing/car-dealership.yml';
import CarDealershipContainer from '@/containers/CarDealership';

export interface CarDealershipPageProps {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    button: { label: string; url: string };
  };
  viewport2: {
    label: string;
    title: string;
    tickCards: { text: string }[];
  };
  viewport3: {
    label: string;
    benefitCards: {
      label: string;
      text: string;
      image: string;
    }[];
  };
  viewport4: {
    title: string;
    description1: string;
    description2: string;
    description3: string;
    description4: string;
    androidAppUrl: string;
    iosAppUrl: string;
    howItWorksCards: {
      title: string;
      text: string;
      image: string;
    }[];
    bottomText1: string;
    bottomText2: string;
  };
  viewport5: {
    title: string;
    leftCardLabel1: string;
    leftCardLabel2: string;
    leftCardText: string;
    rightCardLabel1: string;
    rightCardLabel2: string;
    rightCardText: string;
  };
  viewport6: {
    label: string;
    description1: string;
    description2: string;
    payByLinkCards: {
      count: string;
      label: string;
      text: string;
      image: string;
    }[];
  };
  viewport7: {
    label: string;
    title1: string;
    title2: string;
    description1: string;
    description2: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport8: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport9: {
    bannerLabel1: string;

    bannerDescription: string;
    bannerDescription2: string;
    bannerDescription3: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport10: {
    title: string;
    description: string;
    howToCards: {
      count: string;
      text: string;
      image: string;
    }[];
  };
  viewport11: {
    bannerLabel: string;
    descriptionItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
}
const CarDealership: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
    viewport10,
    viewport11,
  } = carDealershipPageContent as unknown as CarDealershipPageProps;
  return (
    <Main>
      <CarDealershipContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
          viewport8,
          viewport9,
          viewport10,
          viewport11,
        }}
      />
    </Main>
  );
};

export default CarDealership;
