import React from 'react';
import styled from 'styled-components';
import { CarDealershipPageProps } from '@/pages/marketing/automotive';
import FirstViewport from '@/components/FirstViewport';
import Section from '@/components/Section';
import {
  Description,
  H1,
  Body2,
  Subtitle5,
  P,
  H3,
  Subtitle2,
  SecondaryP,
  StrongInitial,
} from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import Vp1Image from '../assets/img/carDealVp1.png';
import TickIcon from '../assets/icons/tickIcon.svg';
import CarouselCard from '@/components/CarouselCard';
import HowItWorksCard from '@/components/HowItWorksCard';
import RefreshIcon from '../assets/icons/refresh.svg';
import invoiceCardLeft from '../assets/img/invoiceCardLeft.png';
import invoiceCardRight from '../assets/img/invoiceCardRight.png';
import LinkIcon from '../assets/icons/link.svg';
import PayByLinkCard from '@/components/PayByLinkCard';
import TickedItems from '@/components/TickedItems';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';
import Vp7Image from '../assets/img/accountantsVp5.png';
import Vp8Image from '../assets/img/accountantsCode.png';
import bannerBg from '../assets/img/bannerFenaTerminal.png';
import BottomMessageType2 from '@/components/BottomMessageType2';
import HowToCard from '@/components/HowToCard';
import Banner from '@/components/Banner';
import banner2Bg from '../assets/img/accountantsBanner.png';

const SecondViewport = styled(Section)`
  background-color: #fff;
  display: flex;
`;

const ThirdVpWrapper = styled.div`
  background: linear-gradient(
    184deg,
    rgba(244, 246, 249, 1) 0%,
    rgba(251, 252, 253, 1) 40%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const ThirdViewport = styled(Section)`
  background-color: #ffffff11;
`;

const FourthViewport = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
`;

const FifthViewport = styled(Section)``;

const SixthViewport = styled(Section)``;

const NinthViewport = styled(Section)``;

const TenthViewport = styled(Section)``;

const EleventhViewport = styled(Section)``;

const Vp2Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  flex-grow: 1;
  flex-shrink: 0;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
`;

const StyledSubtitle5 = styled(Subtitle5)`
  font-size: 26px;
`;

const TickCard = styled.div`
  display: flex;
  flex-basis: 25%;
  margin-bottom: 3rem;
  @media (max-width: 1200px) {
    flex-basis: 100%;
  }
`;

const TickCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
`;

const Tick = styled(TickIcon)`
  margin-right: 1rem;
`;

const Label = styled(H3)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: center;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const HowItWorksCardWrap = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const VpFourDescription = styled(P)`
  text-align: center;
  width: 70%;
  margin-top: 1rem;
`;

const Vp4TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Download = styled.a`
  color: #38b6ff;
  text-decoration: none;
  cursor: pointer;
`;

const BottomText = styled(P)`
  text-align: center;
  margin-top: 2rem;
`;

const InvoiceCardsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const InvoiceCard = styled.div`
  border-radius: 20px;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 5rem;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    margin-bottom: 2rem;
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 0;
  }
`;

const InvoiceCardLeft = styled(InvoiceCard)`
  background: linear-gradient(341.28deg, #c2cedb -38.66%, #f4f7f9 130.75%);
`;
const InvoiceCardRight = styled(InvoiceCard)`
  background: linear-gradient(128.69deg, #14bd89 11.83%, #47ef90 86.41%);
`;

const InvoiceCardTop = styled.div`
  padding: 3rem 3rem 0 3rem;
  @media (max-width: 1200px) {
    padding: 1rem 1rem 0 1rem;
  }
`;

const InvoceCardLabel = styled(Subtitle2)`
  margin: 1rem 0;
`;

const InvoiceCardImg = styled.img`
  align-self: flex-end;
  width: 80%;
  @media (max-width: 1200px) {
    border-bottom-right-radius: 20px;
  }
`;

const PayByLinkCardsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

const VpSixDescription = styled(P)`
  text-align: center;
  margin-top: 1rem;
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const HowToCardsWrap = styled.div`
  display: flex;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const TextWrapper = styled.div`
  padding-right: 0.5rem;
`;

interface CarDealershipProps {
  content: CarDealershipPageProps;
}

const CarDealershipContainer: React.FunctionComponent<CarDealershipProps> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
    viewport10,
    viewport11,
  },
}) => (
  <>
    <FirstViewport img={Vp1Image}>
      <H1>
        <span className="accent-text"> {viewport1.title1}</span>
        {` `}
        {viewport1.title2}
      </H1>
      <Description className="accent-text-black-bold">
        {viewport1.description}
      </Description>

      <ButtonDefault to={viewport1.button.url}>
        {viewport1.button.label}
      </ButtonDefault>
    </FirstViewport>
    <SecondViewport>
      <Body2 className="accent-text-gray">{viewport2.label}</Body2>
      <TickCards>
        <Vp2Text>
          <StyledSubtitle5>{viewport2.title}</StyledSubtitle5>
        </Vp2Text>
        {viewport2.tickCards.map((item) => (
          <TickCard>
            <div>
              <Tick />
            </div>
            <TextWrapper>
              <P>{item.text}</P>
            </TextWrapper>
          </TickCard>
        ))}
      </TickCards>
    </SecondViewport>
    <ThirdVpWrapper>
      <ThirdViewport>
        <Label>{viewport3.label}</Label>
        <CardWrapper>
          {viewport3.benefitCards.map((item) => (
            <CarouselCard
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
      </ThirdViewport>
      <FourthViewport>
        <Label>{viewport4.title}</Label>
        <Vp4TitleWrapper>
          <VpFourDescription>
            {viewport4.description1}
            {` `}
            <Download href={viewport4.androidAppUrl} target="_blank">
              <span className="accent-text-blue">{viewport4.description2}</span>
            </Download>
            {` `}
            or
            {` `}
            <Download href={viewport4.iosAppUrl} target="_blank">
              <span className="accent-text-blue">{viewport4.description3}</span>
            </Download>
            {` `}
            {viewport4.description4}
          </VpFourDescription>
        </Vp4TitleWrapper>

        <HowItWorksCardWrap>
          {viewport4.howItWorksCards.map((item) => (
            <HowItWorksCard
              key={item.title}
              title={item.title}
              text={item.text}
              image={item.image}
            />
          ))}
        </HowItWorksCardWrap>
        <BottomText>
          {viewport4.bottomText1}
          {` `}
          <Download href="https://business.fena.co/" target="_blank">
            {viewport4.bottomText2}
          </Download>
        </BottomText>
      </FourthViewport>
      <FifthViewport>
        <Label>{viewport5.title}</Label>
        <InvoiceCardsWrapper>
          <InvoiceCardLeft>
            <InvoiceCardTop>
              <RefreshIcon />
              <InvoceCardLabel>
                {viewport5.leftCardLabel1}
                {` `}
                <span className="accent-text">{viewport5.leftCardLabel2}</span>
              </InvoceCardLabel>
              <P>{viewport5.leftCardText}</P>
            </InvoiceCardTop>

            <InvoiceCardImg src={invoiceCardLeft} alt="code screenshot" />
          </InvoiceCardLeft>
          <InvoiceCardRight>
            <InvoiceCardTop>
              <LinkIcon />
              <InvoceCardLabel>
                <span className="accent-text-white">
                  {viewport5.rightCardLabel1}
                </span>
                {` `}
                {viewport5.rightCardLabel2}
              </InvoceCardLabel>
              <P className="accent-text-white">{viewport5.rightCardText}</P>
            </InvoiceCardTop>

            <InvoiceCardImg src={invoiceCardRight} alt="code screenshot" />
          </InvoiceCardRight>
        </InvoiceCardsWrapper>
      </FifthViewport>
      <SixthViewport>
        <Label>{viewport6.label}</Label>
        <VpSixDescription>
          {viewport6.description1}
          {` `}
          {viewport6.description2}
        </VpSixDescription>
        <PayByLinkCardsWrap>
          {viewport6.payByLinkCards.map((item) => (
            <PayByLinkCard
              key={item.label}
              number={item.count}
              label={item.label}
              text={item.text}
              imageSrc={item.image}
            />
          ))}
        </PayByLinkCardsWrap>
      </SixthViewport>
    </ThirdVpWrapper>
    <ThirdVpWrapper>
      <FirstViewport imgWidth="50%" withRightPadding img={Vp7Image}>
        <SecondaryP className="accent-text-gray">{viewport7.label}</SecondaryP>
        <H1>
          <span className="accent-text">{viewport7.title1}</span>
          {` `}
          {viewport7.title2}
        </H1>
        <StyledStrong>
          {viewport7.description1}
          {` `}
          {viewport7.description2}
        </StyledStrong>
        <Description>
          <TickedItems items={viewport7.descriptionItems} />
        </Description>
      </FirstViewport>
      <FirstViewportMirrored imgWidth="50%" withRightPadding img={Vp8Image}>
        <H1>
          {viewport8.title1}
          <span className="accent-text"> {viewport8.title2}</span>
          {` `}
          {viewport8.title3}
        </H1>
        <StyledStrong>{viewport8.description}</StyledStrong>
        <Description>
          <TickedItems items={viewport8.descriptionItems} />
        </Description>
      </FirstViewportMirrored>
      <NinthViewport>
        <BottomMessageType2
          bannerLabel1={viewport9.bannerLabel1}
          bannerDescription={viewport9.bannerDescription}
          bannerDescription2={viewport9.bannerDescription2}
          bannerDescription3={viewport9.bannerDescription3}
          button={viewport9.button}
          bannerBg={bannerBg}
        />
      </NinthViewport>
      <TenthViewport>
        <Label>{viewport10.title}</Label>
        <VpSixDescription>{viewport10.description}</VpSixDescription>
        <HowToCardsWrap>
          {viewport10.howToCards.map((item) => (
            <HowToCard
              key={item.count}
              number={item.count}
              text={item.text}
              imageSrc={item.image}
            />
          ))}
        </HowToCardsWrap>
      </TenthViewport>
      <EleventhViewport>
        <Banner
          bgSrc={banner2Bg}
          label={viewport11.bannerLabel}
          descriptionItems={viewport11.descriptionItems}
          buttonUrl={viewport11.button.url}
          buttonLabel={viewport11.button.label}
        />
      </EleventhViewport>
    </ThirdVpWrapper>
  </>
);

export default CarDealershipContainer;
